import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth-guard.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ROUTE_AUTH } from './framework/constants/route.auth.constants';
import { ROUTE_WEBAPP } from './framework/constants/route.webapp.constants';
import { ViewOrDownloadFileComponent } from './pages/webapp/drive/view-or-download-file/view-or-download-file.component';
import * as Sentry from '@sentry/angular';
import { AuthComponent } from './pages/auth/auth.component';
import { BillingRedirectComponent } from './pages/billing/billing-redirect/billing-redirect.component';
import { AdminPannelComponent } from './pages/admin-pannel/admin-pannel.component';
import { GeneralGuard } from './guards/general-guard';

const routes: Routes = [
  {
    path: ROUTE_WEBAPP.BASE,
    loadChildren: () => import(`./pages/webapp/webapp.module`).then((m) => m.WebappModule),
  },
  {
    path: ROUTE_AUTH.BASE,
    resolve: [AuthGuard],
    children: [
      {
        path: '**',
        component: AuthComponent,
      },
    ],
  },
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  {
    path: 'landing',
    loadComponent: () =>
      import(`./pages/landing2/landing2.component`).then((m) => m.Landing2Component),
    resolve: [AuthGuard],
  },
  {
    path: 'admin',
    component: AdminPannelComponent,
    resolve: [GeneralGuard],
  },
  {
    path: 'documents/file/:uuid',
    component: ViewOrDownloadFileComponent,
  },
  {
    path: 'billing',
    children: [
      {
        path: '**',
        component: BillingRedirectComponent,
      },
    ],
  },
  { path: '**', component: NotFoundComponent },
  { path: 'not-found', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class AppRoutingModule {}
